const configuration = {
	header: 'Trees',
	id: 'ca4e4a50-b419-4fa2-804a-b97664066f33',
	description: false,
	content: [
		{
			id: 'd59ff545-246d-4add-91dc-ae64633ce9e9',
			data: true,
			type: 'text',
			label: 'Practice Name',
			fieldInformation: ' ',
		},
		{
			id: 'acfc354a-2ee3-401a-8871-4d07436fc417',
			type: 'multi-list',
			label: 'Farmer Managed Natural Regeneration',
			fieldInformation: ' ',
			valid: false,
			data: true,
		},
		{
			id: '159ac187-121e-4d80-8468-a5b51d6174a1',
			type: 'multi-list',
			label: 'Scattered Trees or Parklands',
			fieldInformation: ' ',
			valid: false,
			data: true,
		},
		{
			id: '60a1a7c2-ca9f-4535-926f-58cc7aa872e7',
			type: 'multi-list',
			label: 'Multistrata Agroforestry',
			fieldInformation: ' ',
			valid: false,
			data: true,
		},
		{
			id: '97db9486-10ff-4b1b-8791-d2a5f7efad56',
			type: 'multi-list',
			label: 'Boundary Planting',
			fieldInformation: ' ',
			valid: false,
			data: true,
		},
		{
			id: '8efe23da-71de-4f98-99fe-f3809760a53d',
			type: 'list',
			label: 'Silvopasture',
			fieldInformation: ' ',
			valid: false,
			data: true,
		},
		{
			id: 'bf872adb-3c9c-453c-b47f-be3cb2296c9d',
			type: 'list',
			label: 'Other Agroforestry',
			fieldInformation: ' ',
			valid: false,
			data: true,
		},
		{
			id: '682b1d0e-cb1e-4044-bcbd-7b4dd3aae511',
			type: 'text area',
			label: 'Notes',
			fieldInformation: ' ',
			valid: false,
			data: true,
		},
		{
			id: '1810d295-e82a-4a28-89c5-c44864a5ded6',
			type: 'input-table',
			label: '',
			fieldInformation: '',
			valid: true,
			headerButtonLabel: 'Add Row',
			placeholder: 'Press Add Row',
			deleteMessage: 'Do you want to delete this row?',
			data: 'table',
			columns: [
				{ field: 'tree_shrub', header: 'Tree or Shrub', info: '' },
				// { field: 'subspecies', header: 'Subspecies', info: '' },
				// { field: 'variety', header: 'Variety', info: '' },
				{ field: 'number', header: 'Number', info: '' },
			],
			content: [
				{
					id: '20b8794a-28a0-4e78-8189-13160c7fa1c2',
					type: 'multi-list',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'tree_shrub',
					data: true,
				},
				{
					id: ' 1489af15-a294-4ecf-8fa8-00e9de795a67',
					type: 'number',
					mandatory: false,
					fieldInformation: '',
					valid: false,
					data: true,
					fractionDigits: 3,
					units: 'self',
					col: 'number',
				},
			],
		},
	],
};

export default configuration;
