const configuration = {
	header: 'Variety',
	id: '0276f97c-2d68-4aa1-a5cb-74d34229c9b2',
	description: false,
	content: [
		{
			id: 'd59ff545-246d-4add-91dc-ae64633ce9e9',
			data: true,
			type: 'text',
			label: 'Practice Name',
			fieldInformation: ' ',
		},
		{
			id: 'eab4dd8d-4760-49ef-bca6-bc925c3f8b19',
			type: 'seasonal-table',
			data: 'table',
			label: '',
			fieldInformation: '',
			valid: false,
			editable: true,
			headerButtonLabel: 'Add Time Period',
			placeholder: 'Press Time Period',
			deleteMessage: 'Do you want to delete this time period?',
			columns: [
				{
					id: '23e0fce5-a056-4478-a835-192071766c0e',
					type: 'text',
					label: 'Site Name',
					fieldInformation: ' ',
					columnNumber: 1,
					valid: false,
					data: true,
				},
				{
					id: '1a7ce77c-a32e-4ef5-ab3d-f313d89a530f',
					type: 'text',
					label: 'Time Period',
					fieldInformation: ' ',
					columnNumber: 1,
					valid: false,
					data: true,
				},
			],
			content: [
				{
					id: '588cb6dd-7384-406f-9583-d47dcdd242fb',
					type: 'list',
					label: 'Variety/Cultivar',
					fieldInformation: ' ',
					valid: true,
					data: true,
					dataSource: 'helper',
					customParentFunction(helperData) {
						return helperData.varietyCrop;
					},
					validation: true,
				},
				{
					id: 'bdd1b82f-9327-424a-9904-c418329e8aa4',
					type: 'list',
					label: 'Choose Crop Practice',
					fieldInformation: ' ',
					valid: true,
					data: true,
					definition: {
						id: '3d5b1d7d-381a-4e22-9011-37654d8f4228',
						type: 'text area',
						label: 'Definition',
						fieldInformation: ' ',
						valid: false,
						disabled: true,
						placeholder: 'Practice Method has no value.',
					},
					validation: true,
				},
				{
					id: '24c3119f-ede6-47a8-a294-b2546ea0ed8a',
					type: 'list',
					label: 'Type',
					fieldInformation: ' ',
					valid: true,
					data: true,
				},
				{
					id: '9cfe8485-757e-48ef-be73-f9ccaa3f9d7d',
					type: 'list',
					label: 'Trait1',
					fieldInformation: ' ',
					valid: true,
					data: true,
				},
				{
					id: 'd6b26051-cc89-4080-82b8-ab59a4dc2b5b',
					type: 'list',
					label: 'Trait2',
					fieldInformation: ' ',
					valid: true,
					data: true,
				},
				{
					id: '9fb2d9fe-e26b-40ed-9258-02c04ad4304e',
					type: 'list',
					label: 'Trait3',
					fieldInformation: ' ',
					valid: true,
					data: true,
				},
				{
					id: '94a27f85-8ceb-4a9e-8f3c-0ca961d4a235',
					type: 'number',
					mandatory: false,
					label: 'Time to Maturity',
					fieldInformation: ' ',
					valid: false,
					suffix: ' days',
					units: 'suffix',
					showButtons: true,
					col: 'error',
					data: true,
				},
				{
					id: 'ec058ab6-5737-4046-9f86-cf297fc5259a',
					type: 'text area',
					label: 'Notes',
					fieldInformation: ' ',
					valid: false,
					data: true,
				},
			],
		},
	],
};

export default configuration;
