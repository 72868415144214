const configuration = {
	header: 'Water Harvesting',
	id: '7aa90db2-a97a-4786-a353-991c22551d9b',
	description: true,
	descriptionList: [{
		type: 'p',
		name: 'water-harvesting-subdesc-1',
	}],
	content: [
		// {
		// 	id: 'd59ff545-246d-4add-91dc-ae64633ce9e9',
		// 	data: true,
		// 	type: 'custom',
		// 	custom: 'preloadedText',
		// 	label: 'Practice Name',
		// 	keyword: 'water_harvest_practices',
		// 	fieldInformation: ' ',
		// 	preload: ['40320471-c9d2-4191-91e4-1097e6965994', '94da6976-1782-40c1-9f7a-38b0ae9d58dd', '3de217f1-a397-4ed7-b874-8af07cd6ca24', '30031246-3591-404e-83e2-e7cccf1b0bff'],
		// 	preloadTarget: 'short_value',
		// },
		{
			id: 'd59ff545-246d-4add-91dc-ae64633ce9e9',
			type: 'text',
			label: 'Practice Name',
			fieldInformation: ' ',
			valid: true,
			data: true,
		},
		{
			id: 'a80e0610-7aa7-4eb8-b77e-0fb6af81b5ae',
			type: 'text area',
			label: 'Notes',
			fieldInformation: ' ',
			valid: false,
			data: true,
		},
		{
			id: 'eab4dd8d-4760-49ef-bca6-bc925c3f8b19',
			type: 'seasonal-table',
			data: 'table',
			label: '',
			fieldInformation: '',
			valid: false,
			editable: true,
			headerButtonLabel: 'Add Time Period',
			placeholder: 'Press Time Period',
			deleteMessage: 'Do you want to delete this time period?',
			columns: [
				{
					id: '23e0fce5-a056-4478-a835-192071766c0e',
					type: 'text',
					label: 'Site Name',
					fieldInformation: ' ',
					columnNumber: 1,
					valid: false,
					data: true,
				},
				{
					id: '1a7ce77c-a32e-4ef5-ab3d-f313d89a530f',
					type: 'text',
					label: 'Time Period',
					fieldInformation: ' ',
					columnNumber: 1,
					valid: false,
					data: true,
				},
			],
			content: [
				{
					id: '40320471-c9d2-4191-91e4-1097e6965994',
					data: true,
					type: 'list',
					label: 'Practice 1',
					// preloadTarget: 'short_value',
					fieldInformation: ' ',
					editable: true,
					definition: {
						id: '25c46158-f453-42c5-a0f1-2c0459e45e9c',
						type: 'text area',
						label: 'Definition',
						fieldInformation: ' ',
						valid: false,
						disabled: true,
						placeholder: 'Practice 1 has no value.',
						rows: 3,
					},
				},
				{
					id: '94da6976-1782-40c1-9f7a-38b0ae9d58dd',
					data: true,
					type: 'list',
					label: 'Practice 2',
					fieldInformation: ' ',
					// preloadTarget: 'short_value',
					editable: true,
					definition: {
						id: '739766de-7ffc-48fc-a0b2-45aa6cdcb130',
						type: 'text area',
						label: 'Definition',
						fieldInformation: ' ',
						valid: false,
						disabled: true,
						placeholder: 'Practice 2 has no value.',
						rows: 3,
					},
				},
				{
					id: '3de217f1-a397-4ed7-b874-8af07cd6ca24',
					data: true,
					type: 'list',
					label: 'Practice 3',
					fieldInformation: ' ',
					// preloadTarget: 'short_value',
					editable: true,
					definition: {
						id: '3fe4e9e1-6c95-47bc-8ec4-21ed3b20da0d',
						type: 'text area',
						label: 'Definition',
						fieldInformation: ' ',
						valid: false,
						disabled: true,
						placeholder: 'Practice 3 has no value.',
						rows: 3,
					},
				},
				{
					id: '30031246-3591-404e-83e2-e7cccf1b0bff',
					data: true,
					type: 'list',
					label: 'Practice 4',
					// preloadTarget: 'short_value',
					fieldInformation: ' ',
					editable: true,
					definition: {
						id: '91e19be2-5345-4aec-b5b1-67e35a87b7b5',
						type: 'text area',
						label: 'Definition',
						fieldInformation: ' ',
						valid: false,
						disabled: true,
						placeholder: 'Practice 4 has no value.',
						rows: 3,
					},
				},
			],
		},
	],
};

export default configuration;
