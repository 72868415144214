const configuration = {
	id: '6a271d18-686e-4a1a-bd79-95c30f94c922',
	content: [
		{
			id: '5be57eb5-6e6a-4a41-914d-94964588707e',
			data: true,
			label: 'Experiments',
			type: 'list',
		},
		{
			id: '12841f10-62c6-439d-9290-4938ab1c124b',
			data: true,
			label: 'Category',
			type: 'list',
			unique: 'Practice',
		},
		{
			id: '8101ad87-e411-4447-b655-a5debb82bebf',
			data: 'table',
			header: 'Herd Group',
		},
		{
			id: 'feec0c82-0fbf-475f-a3e4-3331ed601438',
			data: 'table',
			header: 'Outcomes',
		},
		{
			id: 'b3c2cdd3-9c3b-453a-a3f0-fafbcd664b84',
			data: true,
			header: 'Enter Data',
		},
	],
};

export default configuration;
