const configuration =	{
	header: 'HerdGroup',
	id: '8101ad87-e411-4447-b655-a5debb82bebf',
	content: [
		{
			id: 'a3f88dd5-0286-40d6-bbc3-cbbf874e5f03',
			type: 'input-table',
			label: '',
			fieldInformation: '',
			valid: true,
			data: 'table',
			headerButtonLabel: 'Add Row',
			placeholder: 'Press Add Row',
			deleteMessage: 'Do you want to delete this row?',
			floatingLabel: 'Toggle visible Columns',
			columns: [
				{ field: 'product_name', header: 'Product Name', info: 'Unique name of the animal group, to be used when reporting practices or data.' },
				{ field: 'herd_name', header: 'Herd Name', info: '' },
				{ field: 'herd_subgroup', header: 'Herd Subgroup', info: '' },
				// { field: 'time_period', header: 'Time Period', info: '' },
				{ field: 'scientific_name', header: 'Scientific Name', info: '' },
				{ field: 'common_name', header: 'Common Name', info: '' },
				{ field: 'breed', header: 'Breed', info: '' },
				{ field: 'animal_practice', header: 'Animal Practice', info: '' },
				{ field: 'sex', header: 'Sex', info: '' },
				{ field: 'rearing_stage', header: 'Rearing Stage', info: '' },
				{ field: 'parity', header: 'Parity', info: '' },
				{ field: 'starting_age', header: 'Starting Age', info: '' },
				{ field: 'ending_age', header: 'Ending Age', info: '' },
				{ field: 'age_unit', header: 'Age Unit', info: '' },
				{ field: 'starting_weight', header: 'Starting Weight', info: '' },
				{ field: 'number', header: 'Number', info: '' },
			],
			content: [
				{
					id: '4bbb20e9-296f-4639-b7ee-d543c792c1fc',
					type: 'text',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'product_name',
					data: true,
				},
				{
					id: '93800494-c637-4fae-b887-077845fbffe2',
					type: 'text',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'herd_name',
					data: true,
				},
				{
					id: 'd906e3d1-b481-4e69-89b3-d5bcbf792947',
					type: 'text',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'herd_subgroup',
					data: true,
				},
				{
					id: 'a4ca5446-04cc-42bb-b563-c5770d43d4cc',
					type: 'list',
					label: '',
					fieldInformation: '',
					data: true,
					valid: false,
					col: 'scientific_name',
				},
				{
					id: '1862bce8-39df-44fd-85dd-da38915a6df9',
					type: 'list',
					label: '',
					fieldInformation: '',
					data: true,
					valid: false,
					col: 'common_name',
				},
				{
					id: '0954fd92-9232-42d9-bdb5-e15ec41530ec',
					type: 'custom',
					custom: 'breed-dialog',
					col: 'breed',
					data: 'table',
				},
				{
					id: '9edfafcd-47ee-4265-af75-e9f72e201c07',
					type: 'custom',
					custom: 'breed-dialog',
					col: 'breed',
					data: true,
				},
				{
					id: '7d4b911a-faf7-4eea-a9de-0543ecd76088',
					type: 'text',
					label: '',
					fieldInformation: '',
					data: true,
					valid: false,
					col: 'animal_practice',
				},
				{
					id: 'e1956cc0-bd8a-4b25-a723-bfbddb51964b',
					type: 'list',
					label: '',
					fieldInformation: '',
					data: true,
					valid: false,
					col: 'sex',
				},
				{
					id: '78870c5a-1902-4c16-84b9-6e2fe0fd601b',
					type: 'list',
					label: '',
					fieldInformation: '',
					data: true,
					valid: false,
					col: 'rearing_stage',
				},
				{
					id: '13b0feb9-4555-4869-88c3-a5d25ba4c39c',
					type: 'list',
					label: '',
					fieldInformation: '',
					data: true,
					valid: false,
					col: 'parity',
				},
				{
					id: 'c2ea773d-3f4d-40b3-aaf8-e3b19ea5a43f',
					type: 'number',
					label: '',
					fieldInformation: '',
					data: true,
					valid: false,
					col: 'starting_age',
					fractionDigits: 3,
				},
				{
					id: '76277af1-654f-4c8c-8616-5b63b56b3a4f',
					type: 'number',
					label: '',
					fieldInformation: '',
					data: true,
					valid: false,
					col: 'ending_age',
					fractionDigits: 3,
				},
				{
					id: 'a5400e73-55e5-414a-97f8-94aa52ebaf5c',
					type: 'list',
					label: '',
					fieldInformation: '',
					data: true,
					valid: false,
					col: 'age_unit',
				},
				{
					id: 'aabe6145-a7c1-4589-a94a-a2eeadc09151',
					type: 'number',
					label: '',
					fieldInformation: '',
					data: true,
					valid: false,
					col: 'starting_weight',
					units: 'self',
					fractionDigits: 3,
				},
				{
					id: '788ccb3f-4578-485b-81c6-90d7c812f2b5',
					type: 'number',
					label: '',
					fieldInformation: '',
					data: true,
					valid: false,
					col: 'number',
					units: 'self',
					fractionDigits: 3,
				},
				// {
				// 	id: 'e5cc659e-cfaf-4a49-a3a4-de23d7460fc0',
				// 	type: 'text',
				// 	label: '',
				// 	fieldInformation: '',
				// 	valid: false,
				// 	col: 'year',
				// 	data: true,
				// },
				// {
				// 	id: 'c6e5c14d-5f0f-433e-9f0f-a57c0c369a18',
				// 	type: 'number',
				// 	label: '',
				// 	fieldInformation: '',
				// 	data: true,
				// 	valid: false,
				// 	col: 'depth_upper',
				// 	fractionDigits: 3,
				// 	options: ['Work in progress'],
				// },
			],
		},
	],
};

export default configuration;
