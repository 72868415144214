import React from 'react';
import './styles.css';
import { useTranslate } from '@tolgee/react';
import { translate } from '../../utils';
import era from '../../assets/about/ERA-Illustration-6_SM.jpg';
import peter from '../../assets/about/pete.jpg';
import lolita from '../../assets/about/lolita.jpg';
import todd from '../../assets/about/todd.jpg';
import namita from '../../assets/about/namita.jpg';
import scio from '../../assets/about/SCiO_Logo_Black.png';

const PersonCard = (props) => {
	const { firstName, profile } = props;
	const { t } = useTranslate();
	const getImg = () => {
		switch (firstName) {
		case 'Peter':
			return peter;
		case 'Todd':
			return todd;
		case 'Namita':
			return namita;
		case 'Lolita':
			return lolita;
		default:
			return '';
		}
	};
	return (
		<div className="profile-card">
			<h5>{translate(t, '', `person-card-name-${firstName}`)} - {translate(t, '', `person-card-title-${firstName}`)}</h5>
			{profile ? <a href={profile}>View {firstName}&apos;s Profile</a> : null}
			<img src={getImg()} alt={`Profile Picture ${firstName}`} />
			<p>{translate(t, '', `person-card-body-${firstName}`)}</p>
		</div>
	);
};

const About = () => {
	const { t } = useTranslate();
	
	return (
		<div className="about">
			<div className="page-body">
				<p>{translate(t, '', 'about-body-1')}</p>
				<span className="text-with-link">
					<p>{translate(t, '', 'about-body-2')} <a href="https://github.com/EiA2030/ERAg">ERAg GitHub Repository</a></p>
				</span>
				<h4>{translate(t, '', 'about-subtitle-1')}</h4>
				<img className="era-img" src={era} alt="era-about" />
				<div className="text-groups">
					<h5>{translate(t, '', 'about-subsubtitle-1')}</h5>
					<p>{translate(t, '', 'about-body-3')}</p>
					<h5>{translate(t, '', 'about-subsubtitle-2')}</h5>
					<p>{translate(t, '', 'about-body-4')}</p>
					<h5>{translate(t, '', 'about-subsubtitle-3')}</h5>
					<p>{translate(t, '', 'about-body-5')}</p>
					<h5>{translate(t, '', 'about-subsubtitle-4')}</h5>
					<p>{translate(t, '', 'about-body-6')}</p>
				</div>
				<h4>{translate(t, '', 'about-subtitle-2')}</h4>
				<div>
					<PersonCard firstName="Todd" profile="https://scholar.google.com/citations?user=z7O5sm4fKY8C&hl=th" />
					<PersonCard firstName="Peter" profile="https://alliancebioversityciat.org/who-we-are/peter-steward" />
					<PersonCard firstName="Namita" profile="https://alliancebioversityciat.org/who-we-are/namita-joshi" />
					<PersonCard firstName="Lolita" />
				</div>
				<h4>{translate(t, '', 'about-subtitle-3')}</h4>
				<a href="https://scio.systems/" target="_blank" rel="noreferrer">
					<img className="scio-img" src={scio} alt="era-about" />
				</a>
				<h4>{translate(t, '', 'about-subtitle-4')}</h4>
				<p>{translate(t, '', 'about-body-7')}</p>
				<p>{translate(t, '', 'about-body-8')}</p>
			</div>
		</div>
	);
};
export default About;
