const configuration = {
	header: 'Irrigation',
	id: 'd27f945b-067a-4c84-ac12-7c100d54d8ad',
	description: false,
	content: [
		// {
		// 	id: 'd59ff545-246d-4add-91dc-ae64633ce9e9',
		// 	data: true,
		// 	type: 'custom',
		// 	custom: 'preloadedText',
		// 	fieldInformation: ' ',
		// 	label: 'Practice Name',
		// 	preload: ['89b4a69a-0334-45e9-ade4-da02eac59037', 'a51dc45b-8fc5-4d98-93f2-c4326d5aeb4c'],
		// 	preloadTarget: 'short_value',
		// },
		{
			id: 'd59ff545-246d-4add-91dc-ae64633ce9e9',
			data: true,
			type: 'text',
			fieldInformation: ' ',
			label: 'Practice Name',
			valid: true,
		},
		{
			id: '14bdc8c5-4746-448f-b109-d9d403389d67',
			type: 'text area',
			label: 'Notes',
			fieldInformation: ' ',
			valid: false,
			data: true,
		},
		{
			id: '71a8acba-2abe-44de-aca5-cf93e8b07111',
			type: 'input-table',
			label: '',
			fieldInformation: '',
			valid: true,
			headerButtonLabel: 'Add Row',
			placeholder: 'Press Add Row',
			deleteMessage: 'Do you want to delete this row?',
			columns: [
				{ field: 'app_method', header: 'Application Method', info: '' },
				{ field: 'app_strategy', header: 'Application Strategy', info: '' },
				{ field: 'water_amount', header: 'Amount of Water Applied', info: '' },
				{ field: 'water_type', header: 'Type of Water Applied', info: '' },
			],
			timeFields: 'simple',
			content: [
				{
					id: '89b4a69a-0334-45e9-ade4-da02eac59037',
					data: true,
					type: 'list',
					editable: false,
					definition: {
						id: 'b5074a7c-bfb8-43cc-ae59-91046493287d',
						type: 'text area',
						label: 'Definition',
						fieldInformation: ' ',
						valid: false,
						disabled: true,
						placeholder: 'Application Method has no value.',
					},
					col: 'app_method',
					validation: true,
				},
				{
					id: 'a51dc45b-8fc5-4d98-93f2-c4326d5aeb4c',
					data: true,
					type: 'list',
					editable: false,
					definition: {
						id: 'f124af3f-ef6d-4cc0-97d4-1ca8b8f574ec',
						type: 'text area',
						label: 'Definition',
						fieldInformation: ' ',
						valid: false,
						disabled: true,
						placeholder: 'Application Strategy has no value.',
					},
					col: 'app_strategy',
					validation: true,
				},
				{
					id: '952366c0-fd7a-45d1-b632-37e52b1a20b6',
					type: 'number',
					mandatory: false,
					minNumber: 1,
					label: '',
					fieldInformation: '',
					valid: false,
					showButtons: true,
					col: 'water_amount',
					units: 'self',
					data: true,
					fractionDigits: 3,
				},
				{
					id: 'c05b1c75-7adf-4eef-809c-bdf9db5ec0b1',
					type: 'list',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'water_type',
					data: true,
				},
			],
		},
	],
};

export default configuration;
