const configuration = 	{
	header: 'Bibliographic Information',
	id: '9fde36dd-a5ff-46d8-a592-04fcd88af346',
	data: true,
	content: [
		{
			id: '24b0c1d3-2d74-4b49-ae29-66d99c0a6ded',
			data: true,
			type: 'text',
			label: 'Publication Code',
			fieldInformation: 'Each paper is assigned a unique code, that consists of your initials followed by a random four digit number, eg NN0001.',
			valid: false,
			preload: true,
			disabled: true,
		},
		{
			id: '1b8e2580-1db4-4645-b17c-de890d5483b6',
			data: true,
			type: 'text',
			label: 'Publication Title',
			fieldInformation: ' ',
			valid: false,
			validation: true,
		},
		{
			id: '19a461fc-491e-48a7-8f3d-9223bad0bcd3',
			data: true,
			type: 'text',
			label: 'Author Last Name',
			fieldInformation: 'Publication first author\'s surname.',
			valid: false,
			validation: true,
		},
		{
			id: '50c0f85e-5fbf-4d75-b8ba-012a869bba23',
			data: true,
			type: 'calendar',
			mandatory: false,
			label: 'Publication Year',
			fieldInformation: 'The year of publication',
			valid: true,
			year: true,
			maxDate: true,
			validation: true,
		},
		{
			id: 'b128c1b8-5094-4347-bef5-5f192685dbb8',
			data: true,
			type: 'list',
			label: 'Journal',
			fieldInformation: 'Name of Journal this research is published in.',
			valid: false,
			newVocab: true,
			optionLabel: 'term',
			validation: true,
		},
		{
			id: 'f9e7c4b6-2236-47ee-b307-f89a18b4fa63',
			data: true,
			type: 'text',
			label: 'DOI/Website URL',
			fieldInformation: 'Digital object identifier (DOI) for the publication (e.g. 10.1080/03650340.2017.1411589). Do not include https:// when entering this. If there is no DOI for a publication then report the website of the article. For a publication with no DOI, please enter the full website address where it can be found.',
			validation: true,
		},
		{
			id: '9d292d42-0f25-4f77-b3b1-8c11fb37f0a3',
			type: 'custom',
			custom: 'uploader',
			label: 'Upload PDF',
			fieldInformation: ' ',
			validation: true,
		},
		{
			id: '1db0e04c-cd8e-427f-bb55-84037e755dde',
			data: false,
			type: 'text',
			label: 'Project',
			fieldInformation: 'ACDC 2023',
			valid: false,
			disabled: true,
			placeholder: 'ACDC 2023',
		},
		{
			id: '9fde36dd-a5ff-46d8-a592-04fcd88af346',
			data: true,
			type: 'citation-table',
			header: 'Linked Publications',
			subheader: 'Publications that are referenced in this publication that may present results from the same experiment',
			content: [
				{
					id: '3cc5b368-876b-41d5-bbbc-25886823906a',
					data: true,
					type: 'text',
					label: 'DOI/Website URL',
					fieldInformation: 'Digital object identifier (DOI) for the publication (e.g. 10.1080/03650340.2017.1411589). Do not include https:// when entering this. If there is no DOI for a publication then report the website of the article. For a publication with no DOI, please enter the full website address where it can be found.',
					valid: false,
				},
			],
		},
	],
};

export default configuration;
