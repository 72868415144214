const configuration = {
	id: '6d3e2ad7-97a6-4b3f-916a-62f5e00cc709',
	type: 'input-table',
	label: '',
	fieldInformation: '',
	valid: true,
	data: 'table',
	headerButtonLabel: 'Add Time Period',
	placeholder: 'Press Add Time Period',
	deleteMessage: 'Do you want to delete this season?',
	floatingLabel: 'Toggle visible Columns',
	columns: [
		{ field: 'site_period', header: 'Site & Time Period', info: '' },
		{ field: 'rainfall', header: 'Rainfall (mm)', info: '' },
		{ field: 'max_temp', header: 'Mean Max Temp (°C)', info: '' },
		{ field: 'mean_temp', header: 'Mean Temp (°C)', info: '' },
		{ field: 'min_temp', header: 'Mean Min Temp (°C)', info: '' },
	],
	content: [
		{
			id: '841a4461-caa0-4e28-b585-3aabc3a32817',
			type: 'list',
			label: '',
			fieldInformation: '',
			data: true,
			valid: false,
			optionLabel: 'term',
			col: 'site_period',
			editable: false,
			generatedVocab: true,
		},
		{
			id: 'd0d00b8b-eb67-4f90-b548-d1044a1c7d25',
			type: 'number',
			// minNumber: 1,
			label: '',
			data: true,
			fieldInformation: '',
			valid: false,
			fractionDigits: 3,
			col: 'rainfall',
		},
		{
			id: 'dd374dc9-3034-496d-b008-0a3add8d1f5c',
			type: 'number',
			// minNumber: 1,
			label: '',
			data: true,
			fieldInformation: '',
			valid: false,
			fractionDigits: 3,
			col: 'max_temp',
		},
		{
			id: 'a31ae18b-eb74-4352-94ec-586dd2d0653f',
			type: 'number',
			// minNumber: 1,
			label: '',
			data: true,
			fieldInformation: '',
			valid: false,
			fractionDigits: 3,
			col: 'mean_temp',
		},
		{
			id: 'e48750a4-e976-403c-b313-dfdc5814cf76',
			type: 'number',
			// minNumber: 1,
			label: '',
			data: true,
			fieldInformation: '',
			valid: false,
			fractionDigits: 3,
			col: 'min_temp',
		},
	],
};

export default configuration;
