const configurationCrop =	{
	header: 'Outcomes',
	id: 'feec0c82-0fbf-475f-a3e4-3331ed601438',
	content: [
		{
			id: '81c21c9e-257f-4ba4-ae07-90706c7e3da0',
			type: 'header',
			text: 'Outcome Description',
			category: 'p',
			size: '18px',
			weight: 'bold',
			data: false,
		},
		{
			id: '55c0c7f9-95b9-471c-bb51-a4ce0d1a4f4f',
			type: 'expandable-table',
			label: '',
			fieldInformation: '',
			valid: false,
			editable: true,
			headerButtonLabel: 'Add Indicator',
			placeholder: 'Press Add Indicator',
			deleteMessage: 'Do you want to delete this indicator?',
			data: 'table',
			columns: [
				{
					id: 'c9a5bdbe-044d-40fd-9b46-4cee6d2b9256',
					data: true,
					type: 'list',
					label: 'Outcome',
					filter: true,
					customTermEditMode: 'manual',
				},
				{
					id: 'c2c639af-446d-4d51-a80f-870b74e67ac8',
					data: true,
					type: 'text',
					label: 'Outcome Name',
					subIndex: true,
					generatedFrom: { outcomeNameFunc(helperContext, stepValues) {
						const id = helperContext.helperState.outcomeId;
						const temp = stepValues.get('c2c639af-446d-4d51-a80f-870b74e67ac8');
						if (!temp?.value) {
							return `Outcome ${id}`;
						}
						return undefined;
					} },
				},
			],
			content: [
				{
					id: '930e3bff-b271-440d-9767-053391e0d5f1',
					type: 'text',
					label: 'Unit',
					fieldInformation: ' ',
					valid: false,
					data: true,
				},
				{
					id: 'bdfb0be0-4a80-40d5-8abc-67ddc7348f45',
					type: 'text',
					label: 'Species/Item/Group',
					fieldInformation: ' ',
					valid: false,
					data: true,
				},
				{
					type: 'custom',
					custom: 'outcome-fields',
					data: false,
				},
				{
					id: 'a0a15211-4ce8-4d99-81a7-4efbf5227cd3',
					type: 'text area',
					label: 'Description of partial outcomes (usually economic)',
					fieldInformation: ' ',
					valid: false,
					data: true,
				},
			],
		},
		{
			id: '7d42c091-e003-4f66-9e65-f7393712f546',
			type: 'header',
			text: 'Economic Costs and Benefits',
			category: 'p',
			size: '18px',
			weight: 'bold',
			data: false,
		},
		{
			id: 'a3f88dd5-0286-40d6-bbc3-cbbf874e5f03',
			type: 'input-table',
			label: '',
			fieldInformation: '',
			valid: true,
			data: 'table',
			headerButtonLabel: 'Add Row',
			placeholder: 'Press Add Row',
			deleteMessage: 'Do you want to delete this row?',
			floatingLabel: 'Toggle visible Columns',
			columns: [
				{ field: 'site', header: 'Site', info: '' },
				{ field: 'category', header: 'Variable Category', info: '' },
				{ field: 'variable', header: 'Variable', info: '' },
				{ field: 'variable_details', header: 'Variable Details', info: '' },
				{ field: 'value', header: 'Value', info: '' },
				{ field: 'unit', header: 'Unit', info: '' },
				{ field: 'timeframe', header: 'Timeframe', info: '' },
				{ field: 'year', header: 'Year', info: '' },
			],
			content: [
				{
					id: '255fb141-ee7b-4571-bc55-b75a00f3e899',
					type: 'list',
					label: '',
					fieldInformation: '',
					data: true,
					valid: false,
					col: 'site',
					options: ['Work in progress.'],
				},
				{
					id: 'ec18e0ee-a184-4fa8-8ae4-fde8bf0aa6c0',
					type: 'list',
					label: '',
					fieldInformation: '',
					data: true,
					valid: false,
					col: 'category',
					options: ['Work in progress.'],
				},
				{
					id: '3bcef50b-bc7d-40a1-8785-ab1ad30bb4e4',
					type: 'list',
					label: '',
					fieldInformation: '',
					data: true,
					valid: false,
					col: 'variable',
					options: ['Work in progress.'],
					definition: {

					},
				},
				{
					id: 'f8cfd56e-5bd0-45ea-88b3-fbcf5261b46d',
					type: 'text',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'variable_details',
					data: true,
				},
				{
					id: '76824776-f811-4ddb-8d14-43740ee789e4',
					type: 'text',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'value',
					data: true,
				},
				{
					id: 'df483b81-56e0-47d8-9c8c-537d7c7dfe31',
					type: 'text',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'unit',
					data: true,
				},
				{
					id: '302f1628-7f00-4bd4-a0ac-7fa7960855c9',
					type: 'list',
					label: '',
					fieldInformation: '',
					data: true,
					valid: false,
					col: 'timeframe',
					dataSource: 'timeVocabs',
					getOptions(data) {
						const { timeVocabs } = data;
						console.log(data);
						// const s = experimentsData.get('6fd9d246-99fb-4da3-bb6c-b6e1f4b53cef')?.value;
						// if (s?.term === 'Same for all sites') {
						// 	return [{ term: 'Same for all time periods' }];
						// }
						// const periods = timeVocabs?.periodsVocab?.filter((prd) => {
						// 	return prd.sites.find((site) => site.term === s?.term);
						// });
						const periods = timeVocabs?.periodsVocab?.map((item) => {
							return { term: item.term };
						});
						if (periods.length >= 1) {
							periods.push({ term: 'All times' });
							periods.push({ term: 'Unspecified' });
						}

						if (!periods || periods.length === 0) {
							return [{ term: 'Unspecified' }];
						}
						return periods || [];
					},
					generatedVocab: true,
				},
				{
					id: 'e5cc659e-cfaf-4a49-a3a4-de23d7460fc0',
					type: 'text',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'year',
					data: true,
				},
			],
		},
	],
};

export default configurationCrop;
