const configuration = {
	header: 'Crop Residues and Mulch',
	id: '45b0b9f5-086d-472a-82c0-1d28ea81d8b0',
	description: false,
	content: [

		{
			id: 'd59ff545-246d-4add-91dc-ae64633ce9e9',
			data: true,
			type: 'text',
			label: 'Practice Name',
			fieldInformation: ' ',
		},
		{
			id: '8139eabf-7b01-4270-b713-522221faf847',
			type: 'text area',
			label: 'Notes',
			fieldInformation: ' ',
			valid: false,
			data: true,
		},
		{
			id: 'eab4dd8d-4760-49ef-bca6-bc925c3f8b19',
			type: 'seasonal-table',
			data: 'table',
			label: '',
			fieldInformation: '',
			valid: false,
			editable: true,
			headerButtonLabel: 'Add Time Period',
			placeholder: 'Press Time Period',
			deleteMessage: 'Do you want to delete this time period?',
			columns: [
				{
					id: '23e0fce5-a056-4478-a835-192071766c0e',
					type: 'text',
					label: 'Site Name',
					fieldInformation: ' ',
					columnNumber: 1,
					valid: false,
					data: true,
				},
				{
					id: '1a7ce77c-a32e-4ef5-ab3d-f313d89a530f',
					type: 'text',
					label: 'Time Period',
					fieldInformation: ' ',
					columnNumber: 1,
					valid: false,
					data: true,
				},
			],
			content: [
				{
					id: '89b621ed-0d50-4ba6-8fc1-d83ea7b50d10',
					data: true,
					type: 'list',
					label: 'Does this treatment receive crop residues from the previous season?',
					fieldInformation: ' ',
				},
				{
					id: 'be56e3aa-9b4f-428c-ba04-5fe22d30c5c4',
					data: true,
					type: 'list',
					label: 'What happens to the crop residues from this treatment?',
					fieldInformation: ' ',
				},
				{
					id: '964a462b-6336-404a-b6e9-dd5e2936be3b',
					type: 'header',
					text: 'Total Organic Element NPK',
					category: 'p',
					size: '16px',
					weight: '500',
					data: true,
				},
				{
					id: 'e492a65b-ca20-404e-8df2-fdaa81925d14',
					type: 'number',
					mandatory: false,
					minNumber: 1,
					label: 'Element N',
					fieldInformation: ' ',
					valid: false,
					showButtons: true,
					units: 'e492a65b-ca20-404e-8df2-fdaa81925d14',
					data: true,
					style: { width: '32%' },
				},
				{
					id: '4a8269d8-4ef5-43bc-837c-654759a434e6',
					type: 'number',
					mandatory: false,
					minNumber: 1,
					label: 'Element P',
					fieldInformation: ' ',
					valid: false,
					showButtons: true,
					units: 'e492a65b-ca20-404e-8df2-fdaa81925d14',
					data: true,
					style: { width: '32%' },
				},
				{
					id: '7aa983b6-8425-486f-80d2-f8c20f593671',
					type: 'number',
					mandatory: false,
					minNumber: 1,
					label: 'Element K',
					fieldInformation: ' ',
					valid: false,
					showButtons: true,
					units: 'e492a65b-ca20-404e-8df2-fdaa81925d14',
					data: true,
					style: { width: '32%' },
				},
			],
		},
		{
			id: 'bcc42420-4ee6-438c-9324-fe225ac3873a',
			type: 'input-table',
			label: '',
			fieldInformation: '',
			valid: true,
			headerButtonLabel: 'Add Row',
			placeholder: 'Press Add Row',
			deleteMessage: 'Do you want to delete this row?',
			data: 'table',
			columns: [
				{ field: 'tree', header: 'Tree', info: '' },
				{ field: 'mulch_applied', header: 'Mulch Material Applied', info: '' },
				{ field: 'source', header: 'Source of Material', info: '' },
				{ field: 'amount', header: 'Amount of Material Applied', info: '' },
				{ field: 'unit', header: 'Unit of Material Application', info: '' },
				{ field: 'fate', header: 'The Fate of the Material', info: '' },
				{ field: 'coverage', header: 'Coverage of Material Applied as Mulch', info: '' },
				// { field: 'dd_mm', header: 'dd/mm', info: '' },
				// { field: 'stage', header: 'Crop Stage', info: '' },
				// { field: 'before_after', header: 'Days before/after planting', info: '' },
				{ field: 'desc', header: 'Text Description', info: '' },
			],
			timeFields: 'alt',
			content: [
				{
					id: '78c82455-0016-43d2-a502-cf943a7854fc',
					type: 'list',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'tree',
					data: true,
				},
				{
					id: 'e8e3a0a5-67fa-4d54-a706-ec821f717cf1',
					type: 'list',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'mulch_applied',
					data: true,
				},
				{
					id: 'ce49d395-519a-40df-ba01-d4b283febafa',
					type: 'list',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'source',
					data: true,
				},
				{
					id: 'ed6e1622-11f2-4447-8d93-69c1f8e839b5',
					type: 'number',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'amount',
					data: true,
					fractionDigits: 3,
				},
				{
					id: '442c5d94-d6ee-40a7-a8ed-b8a891b4acec',
					type: 'list',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'unit',
					data: true,
				},
				{
					id: '23950324-c759-4771-9bbc-518ec8f72397',
					type: 'list',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'fate',
					data: true,
				},
				{
					id: '8543e738-1f2b-4d84-99ed-c4b4474bda35',
					type: 'number',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'coverage',
					suffix: '%',
					units: 'suffix',
					data: true,
					fractionDigits: 3,
				},
				// {
				// 	id: 'f5a2185b-abf5-4038-a3da-d7a6ac0f14c8',
				// 	type: 'text',
				// 	label: '',
				// 	fieldInformation: '',
				// 	valid: false,
				// 	col: 'dd_mm',
				// 	data: true,
				// },
				// {
				// 	id: '2a473f83-f859-4262-99ac-9b9f7a91ee40',
				// 	type: 'text',
				// 	label: '',
				// 	fieldInformation: '',
				// 	valid: false,
				// 	col: 'stage',
				// 	data: true,
				// },
				// {
				// 	id: '52061287-3dc1-4648-b604-fa9cca912bba',
				// 	type: 'text',
				// 	label: '',
				// 	fieldInformation: '',
				// 	valid: false,
				// 	col: 'before_after',
				// 	data: true,
				// },
				{
					id: '21858b55-7e7e-4beb-9cee-5ffc86a814d7',
					type: 'text',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'desc',
					data: true,
				},
			],
			extraFields: [
				{
					id: 'f4e19eba-24e9-48ee-829f-8aa8225e6ae5',
					type: 'text',
					label: 'Nutrient',
					fieldInformation: ' ',
					valid: false,
					data: true,
				},
				{
					id: 'e439ecd4-5e95-4c13-86d3-d2ecdb2ea153',
					type: 'list',
					label: 'Dry Weight?',
					fieldInformation: ' ',
					valid: false,
					data: true,
				},
				{
					id: '668612ce-33f2-4b06-8b29-4eaf11984b77',
					type: 'number',
					label: 'Dry Matter',
					fieldInformation: ' ',
					valid: false,
					showButtons: true,
					units: 'self',
					data: true,
				},
				{
					id: '052c32d9-fe36-4438-af81-784e2ac2776a',
					type: 'number',
					label: 'Organic Carbon (OC)',
					fieldInformation: ' ',
					valid: false,
					showButtons: true,
					units: 'self',
					data: true,
				},
				{
					id: 'aa4afdb1-2afc-4263-a41e-a9a05458e01a',
					type: 'number',
					label: 'Nitrogen (Unspecified)',
					fieldInformation: ' ',
					valid: false,
					showButtons: true,
					units: 'self',
					data: true,
				},
				{
					id: '7d340e40-7d31-4321-9b0f-6095e0c5547a',
					type: 'number',
					label: 'Total Nitrogen (TN)',
					fieldInformation: ' ',
					valid: false,
					showButtons: true,
					units: 'self',
					data: true,
				},
				{
					id: 'd2fd1808-d902-4426-93fa-f28fc913880d',
					type: 'number',
					label: 'Available Nitrogen (AN)',
					fieldInformation: ' ',
					valid: false,
					showButtons: true,
					units: 'self',
					data: true,
				},
				{
					id: 'fb495241-1e45-487d-802c-51be0015afe9',
					type: 'text',
					label: ' Carbon: Nitrogen (C/N) Ratio',
					fieldInformation: ' ',
					valid: false,
					data: true,
				},
				{
					id: '86e772a1-e2c0-4c8a-9822-9f692bb4508d',
					type: 'number',
					label: 'Phosphorous (Unspecified)',
					fieldInformation: ' ',
					valid: false,
					showButtons: true,
					units: 'self',
					data: true,
				},
				{
					id: '9fb415a4-b91b-40a4-be76-822ecc1dcb17',
					type: 'number',
					label: 'Total Phosphorous (TP)',
					fieldInformation: ' ',
					valid: false,
					showButtons: true,
					units: 'self',
					data: true,
				},
				{
					id: '4b6e53ac-451d-486f-87f0-9566e335597b',
					type: 'number',
					label: 'Available Phosphorous (AP)',
					fieldInformation: ' ',
					valid: false,
					showButtons: true,
					units: 'self',
					data: true,
				},
				{
					id: '21f2c5da-3ad5-4f2e-bbde-baa536c7d6f8',
					type: 'number',
					label: ' Potassium (K)',
					fieldInformation: ' ',
					valid: false,
					showButtons: true,
					units: 'self',
					data: true,
				},
				{
					id: '72d254a0-5cb1-4e2d-aa65-d6e7fa41bca5',
					type: 'number',
					label: 'pH',
					fieldInformation: ' ',
					valid: false,
					showButtons: true,
					units: 'self',
					data: true,
				},
			],
			extraFieldsLabel: 'Composition',
		},
	],
};

export default configuration;
