const configuration = {
	header: 'Nutrition',
	id: '47f30473-e49b-4068-9ab0-48f743eff6c4',
	content: [
		{
			id: 'd59ff545-246d-4add-91dc-ae64633ce9e9',
			data: true,
			type: 'text',
			label: 'Practice Name',
			fieldInformation: ' ',
		},
		{
			id: 'c8f724ec-d7d2-4d9b-ad7d-8ad2fb0ae43c',
			type: 'list',
			label: 'Composition from dry matter?',
			fieldInformation: ' ',
			valid: false,
			col: 'variable',
			data: true,
		},
		{
			type: 'header',
			text: 'Variable Selection Table',
			category: 'p',
			size: '19px',
			weight: '900',
		},
		{
			id: 'ab77167d-7063-4398-aaa6-f3a95dd5365d',
			type: 'input-table',
			label: '',
			fieldInformation: '',
			valid: true,
			headerButtonLabel: 'Add Row',
			placeholder: 'Press Add Row',
			deleteMessage: 'Do you want to delete this row?',
			data: 'table',
			columns: [
				{ field: 'variable', header: 'Variable Name', info: '' },
				{ field: 'unit', header: 'Unit of Measurement', info: '' },
				{ field: 'measured', header: 'Measured or Estimated?', info: '' },
				{ field: 'notes', header: 'Notes', info: '' },
			],
			content: [
				{
					id: '8c35cffb-c96d-4c93-a7af-b9799cbefeaa',
					type: 'list',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'variable',
					data: true,
				},
				{
					id: '0206e0e9-ce9a-44a6-98b4-26a211c4fd74',
					type: 'list',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'unit',
					data: true,
				},
				{
					id: '7a4b30cb-ec1f-4b49-ad66-55d2e0b3db1e',
					type: 'list',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'measured',
					data: true,
				},
				{
					id: '084b2255-5b3e-4c58-a93f-7f856906144f',
					type: 'text',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'notes',
					data: true,
				},
			],
		},
		{
			type: 'header',
			text: 'Value Entry Table',
			category: 'p',
			size: '19px',
			weight: '900',
		},
		{
			id: 'b48536e5-f390-46ce-9043-21a85fc10a7b',
			type: 'input-table',
			label: '',
			fieldInformation: '',
			valid: true,
			headerButtonLabel: 'Add Row',
			placeholder: 'Press Add Row',
			deleteMessage: 'Do you want to delete this row?',
			data: 'table',
			columns: { targetTable: 'ab77167d-7063-4398-aaa6-f3a95dd5365d', targetField: '8c35cffb-c96d-4c93-a7af-b9799cbefeaa' },
		},
	],
};

export default configuration;
