import React, { useMemo, useContext } from 'react';
import { weightGainFields, economicFields, npvFields, soilFields, fcrFields } from './configurations';
// eslint-disable-next-line import/no-cycle
import Field from '../../../../index';
import { HelperContext } from '../../../../../../context';

const OutcomeFields = (props) => {
	const { stepValues, setStepValues, id, compositeId, disabled } = props;

	const { helperContextValue } = useContext(HelperContext);
    
	const configuration = useMemo(() => {
		let outcomeUuid;
		if (helperContextValue.editing.submissionType === 'crop') {
			outcomeUuid = 'c9a5bdbe-044d-40fd-9b46-4cee6d2b9256';
		} if (helperContextValue.editing.submissionType === 'livestock') {
			outcomeUuid = '2a4e7877-0c82-44b9-923b-288f8d9dc98d';
		}
		const outcome = stepValues?.get(id).get(outcomeUuid)?.value?.meta;

		const config = (() => {
			switch (outcome) {
			case 'cost':
				return economicFields;
			case 'soil':
				return soilFields;
			case 'FCR/PCR':
				return fcrFields;
			case 'NPV':
				return npvFields;
			case 'weight':
				return weightGainFields;
			default:
				return [];
			}
		})();

		return config || [];
	}, [stepValues]);

	const renderFields = () => {
		return configuration?.map((item) => {
			return (
				<Field configuration={item} stepValues={stepValues} setStepValues={setStepValues} id={id} disabled={disabled} compositeId={compositeId} />
			);
		});
	};
	return (
		renderFields()
	);
};

export default OutcomeFields;
