const breedsConfiguration = [
	{
		id: '94407068-fb38-4109-8a7f-9aa74a256e34',
		type: 'text',
		label: 'Name of Breed',
		fieldInformation: '',
		valid: false,
		data: true,
	},
	{
		id: '9652f146-ce44-47c0-b6f1-0171490402aa',
		type: 'number',
		label: '% Breed',
		fieldInformation: '',
		valid: false,
		data: true,
		fractionDigits: 3,
	},
	{
		id: '2a644981-8c62-40cc-b688-97ff5b887501',
		type: 'text',
		label: 'Sex Breed',
		fieldInformation: '',
		valid: false,
		data: true,
	},
	// {
	// 	id: '93800494-c637-4fae-b887-077845fbffe2',
	// 	type: 'text',
	// 	label: 'Name of Breed 2',
	// 	fieldInformation: '',
	// 	valid: false,
	// 	data: true,
	// 	style: { width: '30%' },
	// },
	// {
	// 	id: '93800494-c637-4fae-b887-077845fbffe2',
	// 	type: 'text',
	// 	label: '% Breed 2',
	// 	fieldInformation: '',
	// 	valid: false,
	// 	data: true,
	// 	style: { width: '30%' },
	// },
	// {
	// 	id: '93800494-c637-4fae-b887-077845fbffe2',
	// 	type: 'text',
	// 	label: 'Sex Breed 2',
	// 	fieldInformation: '',
	// 	valid: false,
	// 	data: true,
	// 	style: { width: '30%' },
	// },
	// {
	// 	id: '93800494-c637-4fae-b887-077845fbffe2',
	// 	type: 'text',
	// 	label: 'Name of Breed 3',
	// 	fieldInformation: '',
	// 	valid: false,
	// 	data: true,
	// 	style: { width: '30%' },
	// },
	// {
	// 	id: '93800494-c637-4fae-b887-077845fbffe2',
	// 	type: 'text',
	// 	label: '% Breed 3',
	// 	fieldInformation: '',
	// 	valid: false,
	// 	data: true,
	// 	style: { width: '30%' },
	// },
	// {
	// 	id: '93800494-c637-4fae-b887-077845fbffe2',
	// 	type: 'text',
	// 	label: 'Sex Breed 3',
	// 	fieldInformation: '',
	// 	valid: false,
	// 	data: true,
	// 	style: { width: '30%' },
	// },
	// {
	// 	id: '93800494-c637-4fae-b887-077845fbffe2',
	// 	type: 'text',
	// 	label: 'Name of Breed 4',
	// 	fieldInformation: '',
	// 	valid: false,
	// 	data: true,
	// 	style: { width: '30%' },
	// },
	// {
	// 	id: '93800494-c637-4fae-b887-077845fbffe2',
	// 	type: 'text',
	// 	label: '% Breed 4',
	// 	fieldInformation: '',
	// 	valid: false,
	// 	data: true,
	// 	style: { width: '30%' },
	// },
	// {
	// 	id: '93800494-c637-4fae-b887-077845fbffe2',
	// 	type: 'text',
	// 	label: 'Sex Breed 4',
	// 	fieldInformation: '',
	// 	valid: false,
	// 	data: true,
	// 	style: { width: '30%' },
	// },
];

export default breedsConfiguration;
