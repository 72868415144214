const configuration = {
	header: 'Digestibility',
	id: '23964cc6-185a-4e4f-85c6-8fe66e2eef89',
	// description: true,
	// descriptionList: [
	// 	{
	// 		type: 'list',
	// 		content: [
	// 			{ name: 'fert-subdesc-1' },
	// 			{ name: 'fert-subdesc-2' },
	// 		],
	// 	},
	// 	{
	// 		type: 'p',
	// 		name: 'fert-subdesc-3',
	// 	},
	// ],
	content: [
		{
			id: 'd59ff545-246d-4add-91dc-ae64633ce9e9',
			data: true,
			type: 'text',
			label: 'Practice Name',
			fieldInformation: ' ',
		},
		{
			id: '8836895c-f9c3-427c-82c7-1247bfe2c8c6',
			type: 'list',
			label: 'Digestibility from dry matter?',
			fieldInformation: ' ',
			valid: false,
			data: true,
		},
		{
			type: 'header',
			text: 'Variable Selection Table',
			category: 'p',
			size: '19px',
			weight: '900',
		},
		{
			id: '642a00af-5590-472d-9c0f-50b4b0fe760a',
			type: 'input-table',
			label: '',
			fieldInformation: '',
			valid: true,
			headerButtonLabel: 'Add Row',
			placeholder: 'Press Add Row',
			deleteMessage: 'Do you want to delete this row?',
			data: 'table',
			columns: [
				{ field: 'variable', header: 'Variable Name', info: '' },
				{ field: 'unit', header: 'Unit of Measurement', info: '' },
				{ field: 'focus', header: 'Diet/Item or Nutrient is the Focus?', info: '' },
				{ field: 'measured', header: 'Measured or Estimated?', info: '' },
				{ field: 'notes', header: 'Notes', info: '' },
			],
			content: [
				{
					id: '8836895c-f9c3-427c-82c7-1247bfe2c8c6',
					type: 'list',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'variable',
					data: true,
				},
				{
					id: 'f08de84a-3758-468c-bc4b-ba8f33eb0b38',
					type: 'list',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'unit',
					data: true,
				},
				{
					id: 'e1358d29-003c-4aa9-beef-ae29871d9552',
					type: 'list',
					label: '',
					fieldInformation: '',
					minWords: 1,
					maxWords: 1,
					valid: false,
					col: 'focus',
					data: true,
				},
				{
					id: '7128bbd5-b7c2-4f23-8b6c-b6a3a298b1dc',
					type: 'list',
					label: '',
					fieldInformation: '',
					minWords: 1,
					maxWords: 1,
					valid: false,
					col: 'measured',
					data: true,
				},
				{
					id: '526602ec-7b4c-4845-a7a9-d580f1dfc500',
					type: 'text',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'notes',
					data: true,
				},
			],
		},
		{
			type: 'header',
			text: 'Value Entry Table',
			category: 'p',
			size: '19px',
			weight: '900',
		},
		{
			id: '4247a7a2-d0d7-44f1-b98f-5502e4ad1aec',
			type: 'input-table',
			label: '',
			fieldInformation: '',
			valid: true,
			headerButtonLabel: 'Add Row',
			placeholder: 'Press Add Row',
			deleteMessage: 'Do you want to delete this row?',
			data: 'table',
			columns: { targetTable: '642a00af-5590-472d-9c0f-50b4b0fe760a', targetField: '8836895c-f9c3-427c-82c7-1247bfe2c8c6' },
		},
	],
};

export default configuration;
