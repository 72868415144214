import React from 'react';
import './styles.css';
import scio from '../../assets/about/SCiO_Logo_Black.png';
import idrc from '../../assets/about/idrc-logo.png';

const Footer = () => (
	<div className="footer p-grid">
		<a href="https://www.idrc-crdi.ca/en" target="_blank" rel="noreferrer"><img className="idrc-img" src={idrc} alt="IDRC Logo" /></a>
		<div className="powered-by-scio">
			<a href="https://scio.systems/" target="_blank" rel="noreferrer">
				<p>powered by</p>
				<img className="scio-img" src={scio} alt="era-about" />
			</a>
		</div>
	</div>
);

export default Footer;
