const configuration = {
	header: 'Grazing Management',
	id: '829995ca-bd4f-4838-9616-f941aa68110e',
	content: [
		{
			id: 'f0e9665f-7a74-410a-a893-1b1e159de0e1',
			data: true,
			type: 'text',
			label: 'Practice Name',
			fieldInformation: ' ',
		},
		{
			id: '6cefeaf7-0169-4fe2-a1ca-13b77b2a6176',
			type: 'seasonal-table',
			data: 'table',
			label: '',
			fieldInformation: '',
			valid: false,
			editable: true,
			headerButtonLabel: 'Add Time Period',
			placeholder: 'Press Time Period',
			deleteMessage: 'Do you want to delete this time period?',
			columns: [
				{
					id: '23e0fce5-a056-4478-a835-192071766c0e',
					type: 'text',
					label: 'Site Name',
					fieldInformation: ' ',
					columnNumber: 1,
					valid: false,
					data: true,
				},
				{
					id: '1a7ce77c-a32e-4ef5-ab3d-f313d89a530f',
					type: 'text',
					label: 'Time Period',
					fieldInformation: ' ',
					columnNumber: 1,
					valid: false,
					data: true,
				},
			],
			content: [
				{
					id: 'b2a156a3-75d4-42dc-881d-a1141f79d2df',
					type: 'list',
					label: 'Is grazing present?',
					fieldInformation: ' ',
					valid: false,
					data: true,
				},
				{
					id: '5f83cbaf-5570-4aeb-ba7f-c4fbf8c6d8c3',
					type: 'multi-list',
					label: 'Types of Grazing Present',
					fieldInformation: ' ',
					valid: false,
					data: true,
				},
				{
					id: '044831f2-5603-4a80-8d69-73f919bab51f',
					type: 'list',
					label: 'Is grazing management fixed or adaptive?',
					fieldInformation: ' ',
					valid: false,
					data: true,
				},
				{
					id: '7520dead-e803-404c-ae23-82720b1a49b1',
					type: 'list',
					label: 'Which factor(s) are used to inform adaptive grazing management?',
					fieldInformation: ' ',
					valid: false,
					data: true,
				},
				{
					id: '8eadbd60-6bde-4749-bcbc-0a0b8add843b',
					type: 'list',
					label: 'Is Burning Present?',
					fieldInformation: ' ',
					valid: false,
					data: true,
				},
				{
					id: '1bfac186-2b10-45f1-9267-26410cb00c5c',
					type: 'number',
					label: 'Total Grazed Area',
					fieldInformation: ' ',
					valid: false,
					columnNumber: 0,
					data: true,
					units: 'self',
				},
				{
					id: 'ccafcc7e-d403-4fa7-8cc3-8bce4c22628a',
					type: 'number',
					label: 'Total Grazed Area Fixed Stocking Rate',
					fieldInformation: ' ',
					valid: false,
					columnNumber: 0,
					data: true,
					units: 'self',
				},
				{
					id: 'a51715ca-30fa-42cd-8d96-0cbc3fb4d98a',
					type: 'number',
					label: 'Total Time Grazing',
					fieldInformation: ' ',
					valid: false,
					columnNumber: 0,
					data: true,
					units: 'self',
				},
				{
					id: '5ac7204e-c63e-4f22-9a89-e5613abdd986',
					type: 'number',
					label: 'Number of sub-divisions or strips',
					fieldInformation: ' ',
					valid: false,
					columnNumber: 0,
					data: true,
				},
				{
					id: '4b0bf8bc-8612-4dc2-88d7-8bbd05772c9c',
					type: 'number',
					label: 'Area of sub-divisions or strips',
					fieldInformation: ' ',
					valid: false,
					columnNumber: 0,
					data: true,
					units: 'self',
				},
				{
					id: '581bbe85-e090-45d7-8898-5ad93fb88f97',
					type: 'number',
					label: 'Sub-division Fixed Stocking Rate',
					fieldInformation: ' ',
					valid: false,
					columnNumber: 0,
					data: true,
					units: 'self',
				},
				{
					id: 'fd1bd4f1-2fbc-49a1-bf2b-7851fea336ff',
					type: 'number',
					label: 'Cycle Length (Rest Period + Grazing)',
					fieldInformation: ' ',
					valid: false,
					columnNumber: 0,
					data: true,
				},
				{
					id: '496d69a3-1778-45cc-b859-840ba6932f03',
					type: 'number',
					label: 'Rest Period Length (Not Grazed)',
					fieldInformation: ' ',
					valid: false,
					columnNumber: 0,
					data: true,
				},
				{
					id: '934169a4-5ea4-4ed4-99e4-199df3e77f62',
					type: 'number',
					label: 'Grazing Period Length',
					fieldInformation: ' ',
					valid: false,
					columnNumber: 0,
					data: true,
				},
				{
					id: '3e8b48c2-ce2e-4bcb-993f-13470861f815',
					type: 'number',
					label: 'Time unit',
					fieldInformation: ' ',
					valid: false,
					columnNumber: 0,
					data: true,
				},
				{
					id: 'daf44ab6-de57-4e83-b3d5-41717a8bdfaa',
					type: 'number',
					label: 'Target sward height (cm)',
					fieldInformation: ' ',
					valid: false,
					columnNumber: 0,
					data: true,
					units: 'suffix',
					suffix: ' cm',
				},
				{
					id: 'f3dd72eb-4423-4d2c-8215-7039bd028068',
					type: 'number',
					label: 'Max sward height (cm)',
					fieldInformation: ' ',
					valid: false,
					columnNumber: 0,
					data: true,
					units: 'suffix',
					suffix: ' cm',
				},
				{
					id: 'e93d7bed-a549-4d45-9df5-5cd20711947f',
					type: 'number',
					label: 'Target pasture biomass',
					fieldInformation: ' ',
					valid: false,
					columnNumber: 0,
					data: true,
					units: 'self',
				},
				{
					id: '9296913d-3031-4e61-91a1-5e142ba8527d',
					type: 'list',
					label: 'Shade available?',
					fieldInformation: ' ',
					valid: false,
					data: true,
				},
				{
					id: 'dbbceab6-b3f7-476b-b5c9-0321d11317e0',
					data: true,
					type: 'text',
					label: 'Notes',
					fieldInformation: ' ',
				},
			],
		},
		// {
		// 	id: 'b6ada569-13b8-4211-9d20-146053f96e08',
		// 	type: 'list',
		// 	label: 'Time Period Name (e.g. season)',
		// 	fieldInformation: ' ',
		// 	valid: false,
		// 	data: true,
		// 	options: ['Work in progress'],
		// },
	],
};

export default configuration;
