/* eslint-disable max-len */
const configuration = {
	header: 'Management',
	id: 'a02a64ce-8637-46fb-ad52-301e5e616f2d',
	content: [
		{
			id: 'be7817c9-10ac-4669-b131-ae077e22855d',
			type: 'expandable-table',
			data: 'table',
			label: '',
			fieldInformation: '',
			valid: false,
			editable: true,
			headerButtonLabel: 'Add Site',
			placeholder: 'Press Add Site',
			deleteMessage: 'Do you want to delete this site?',
			columns: [
				{
					id: 'f24bc945-67b9-4d8d-a517-c9ddb1b9806d',
					data: true,
					type: 'list',
					label: 'Country',
					filter: true,
					customTermEditMode: 'manual',
				},
				{
					id: 'b6f2545f-c745-4902-a3e5-852f775a9190',
					data: true,
					type: 'list',
					label: 'Site Name',
					filter: true,
					customTermEditMode: 'manual',
				},
			],
			content: [
				{
					id: '9e675a6e-d6c1-4af9-860e-649e673fd1c2',
					type: 'tabs',
					data: 'tab',
					label: 'Reps',
					fieldInformation: '',
					valid: false,
					tabs: [
						{
							header: 'Location',
							id: '0efd6c2c-3f12-40a3-991a-d829ae6a5f4c',
							content: [
								{
									id: '65204e6e-022b-42f1-b50c-e7028f857243',
									type: 'list',
									data: true,
									label: 'What is the context of the experiment?',
									fieldInformation: ' ',
									valid: false,
									newVocab: true,
									optionLabel: 'term',
									validation: true,
								},
								{
									id: 'cf23b727-9e04-48b7-ba64-b2b7bd02ffc1',
									type: 'list',
									label: 'How is the location reported?',
									fieldInformation: ' ',
									data: true,
									valid: false,
									newVocab: true,
									optionLabel: 'term',
									validation: true,
								},
								{
									id: '38fdf10b-aa3e-488d-8003-fd5a01619f0d',
									type: 'list',
									label: 'Does the location contain sub-locations?',
									fieldInformation: ' ',
									data: true,
									valid: false,
								},
								{
									id: 'c6510ff3-86e2-4daa-a8a8-ea3b738b3294',
									type: 'map',
									data: 'map',
									label: 'Location',
									content: [
										{
											id: '762cd744-775e-49bd-be05-1b2b8552ba65',
											type: 'header',
											text: 'Enter location latitude and longitude',
											category: 'p',
											size: '16px',
											weight: 'bold',
										},
										{
											id: '919ed9c2-9e55-4adb-a010-b9c72cf7679f',
											type: 'header',
											tolgeeKey: 'sites-longlat-desc',
											text: 'Enter the location either as decimal degrees or as degrees minutes seconds (using the calculator).',
											category: 'p',
											size: '14px',
											weight: '500',
										},
										{
											id: 'ef299ba9-11b7-4424-b00f-b280b6e58f76',
											type: 'custom',
											custom: 'latlong',
											label: 'Degrees/Minutes/Seconds Calculator',
											content: [
												{
													id: '7bebd3b0-91c5-4473-a654-1c44b00824f4',
													type: 'header',
													text: 'Latitude',
													category: 'p',
													size: '16px',
													weight: '500',
												},
												{
													id: '075d8c9e-344b-4ded-92e4-0c57a72585f9',
													type: 'number',
													// minNumber: 1,
													label: 'Degrees',
													tolgeeKey: 'degrees-header',
													fieldInformation: ' ',
													valid: false,
													data: true,
													style: { width: '30%' },
												},
												{
													id: '52171869-c973-44bc-90c0-4b40decf3400',
													type: 'number',
													// minNumber: 1,
													label: 'Minutes',
													fieldInformation: ' ',
													valid: false,
													data: true,
													style: { width: '30%' },
												},
												{
													id: '9bc6a6d7-8d0f-41e2-844b-bd8aede1610f',
													type: 'number',
													// minNumber: 1,
													label: 'Seconds',
													fieldInformation: ' ',
													valid: false,
													data: true,
													style: { width: '30%' },
												},
												{
													id: 'f2bf4953-0435-4096-9dea-63bdd1b9464d',
													type: 'custom',
													custom: 'selector',
													// minNumber: 1,
													label: 'Hemisphere:',
													fieldInformation: ' ',
													data: true,
													options: ['N', 'S'],
												},
												{
													id: '7bebd3b0-91c5-4473-a654-1c44b00824f4',
													type: 'header',
													text: 'Longitude',
													category: 'p',
													size: '16px',
													weight: '500',
												},
												{
													id: '6149bba1-43e8-47e2-83e3-c471ecfa9700',
													type: 'number',
													// minNumber: 1,
													label: 'Degrees',
													tolgeeKey: 'degrees-header',
													fieldInformation: ' ',
													valid: false,
													data: true,
													style: { width: '30%' },
												},
												{
													id: 'b3730ff9-e33d-44b7-b3e4-acff1c8a3665',
													type: 'number',
													// minNumber: 1,
													label: 'Minutes',
													fieldInformation: ' ',
													valid: false,
													data: true,
													style: { width: '30%' },
												},
												{
													id: '34d803bc-8724-43f0-b7a8-957ef9685328',
													type: 'number',
													// minNumber: 1,
													label: 'Seconds',
													fieldInformation: ' ',
													valid: false,
													data: true,
													style: { width: '30%' },
												},
												{
													id: '4eacfe8e-b717-4187-b340-e45311504b4f',
													type: 'custom',
													custom: 'selector',
													// minNumber: 1,
													label: 'Hemisphere:',
													fieldInformation: ' ',
													data: true,
													options: ['N', 'S'],
												},
											], 
										},
										{
											id: '3e141d50-ed1d-4522-b562-ce4b79bb5b82',
											type: 'header',
											text: 'Decimal Degrees',
											category: 'p',
											size: '15px',
											weight: '500',
										},
										{
											id: 'faa84f33-b3ea-443b-a682-7faf78e1d84f',
											type: 'number',
											// minNumber: 1,
											label: 'Latitude',
											fieldInformation: ' ',
											valid: false,
											data: true,
											suffix: ' degrees',
											style: { width: '48%' },
											fractionDigits: 3,
											validation: true,
										},
										{
											id: '6945fb73-3e26-403a-8d43-2cdab030b09b',
											type: 'number',
											// minNumber: 1,
											label: 'Longitude',
											fieldInformation: ' ',
											valid: false,
											data: true,
											suffix: ' degrees',
											style: { width: '48%' },
											fractionDigits: 3,
											validation: true,
										},
										{
											id: 'a10415f0-5a5d-4c8f-9928-921b82ac2801',
											type: 'header',
											text: 'How precise is this location?',
											category: 'p',
											size: '16px',
											weight: 'bold',
										},
										{
											id: '092c5ee0-780b-4e36-a521-0af741cf1d8a',
											type: 'header',
											tolgeeKey: 'sites-precise-desc',
											text: 'How accurate do we think the reporting of the location is? The uncertainty of a location refers to the level of doubt or error associated with the accuracy of a geographic position, represented as a range or an error margin',
											category: 'p',
											size: '14px',
											weight: '500',
										},
										{
											id: '89e3af79-c767-4e07-ad16-1c2233e07c96',
											type: 'number',
											// minNumber: 1,
											label: 'Latitude Uncertainty',
											fieldInformation: 'Longitude and latitude are geographic coordinates that specify the position of a point on the Earth\'s surface, with longitude measuring east-west position and latitude measuring north-south position.',
											valid: false,
											data: true,
											suffix: ' m',
											fractionDigits: 3,
											style: { width: '48%' },
										},
										{
											id: '3abd0c53-e453-40e1-9860-d2e2a4e7f5d5',
											type: 'number',
											// minNumber: 1,
											label: 'Longitude Uncertainty',
											fieldInformation: 'Longitude and latitude are geographic coordinates that specify the position of a point on the Earth\'s surface, with longitude measuring east-west position and latitude measuring north-south position.',
											valid: false,
											data: true,
											suffix: ' m',
											fractionDigits: 3,
											style: { width: '48%' },
										},
										{
											id: 'e0781bd7-0268-45a0-b862-5b98428a459a',
											type: 'number',
											// minNumber: 1,
											label: 'Radius Uncertainty',
											fieldInformation: 'A radius is a measure of distance from the centre of a circle or sphere to its perimeter or surface.',
											data: true,
											valid: false,
											suffix: ' m',
											fractionDigits: 3,
											// style: { width: '32%' },
										},
										{
											id: 'b266e5a1-473e-46a6-8eac-9544db697846',
											type: 'text',
											label: 'Google Link',
											data: true,
											fieldInformation: ' ',
											valid: false,
											col: 'sand',
											disabled: true,
											preload: true,
										},
									],
								},
							],
						},
						{
							header: 'Soils',
							id: '955d1584-36bb-456a-94ac-f53cf3458a2b',
							content: [
								{
									id: '1560882c-4596-4b6f-b5f6-2aa04520c999',
									type: 'header',
									data: false,
									text: 'This section is used to record detailed soil properties at the beginning of an experiment, including texture, pH, nutrient content, and other relevant information. You can add several lines to the table where soil properties are reported for multiple depths. Don\'t record soil properties measured after an experiment has begun here.',
									category: 'p',
									size: '1em',
									weight: '500',
									tolgeeKey: 'soils-desc',
								},
								{
									id: 'ba6eeb96-32db-4de6-aca1-cad0ed9445cd',
									type: 'input-table',
									label: '',
									fieldInformation: '',
									valid: true,
									data: 'table',
									headerButtonLabel: 'Add Soil',
									placeholder: 'Press Add Soil',
									deleteMessage: 'Do you want to delete this soil?',
									floatingLabel: 'Toggle visible Columns',
									columns: [
										{ field: 'depth_upper', header: 'Depth (Upper)', info: '' },
										{ field: 'depth_lower', header: 'Depth (Lower)', info: '' },
										{ field: 'sand', header: 'Sand', info: '' },
										{ field: 'silt', header: 'Silt', info: '' },
										{ field: 'clay', header: 'Clay', info: '' },
										{ field: 'bulk_density', header: 'Bulk Density', info: '' },
										{ field: 'total_carbon', header: 'Total Carbon', info: '' },
										{ field: 'soil_organic_carbon', header: 'Soil Organic Carbon Content (SOC)', info: '' },
										{ field: 'soc_method', header: 'SOC Measurement Method', info: '' },
										{ field: 'soil_organic_matter', header: 'Soil Organic Matter Content (SOM)', info: '' },
										{ field: 'som_method', header: 'SOM Measurement Method', info: '' },
										{ field: 'soil_ph', header: 'Soil pH', info: '' },
										{ field: 'ph_method', header: 'pH Measurement Method', info: '' },
										{ field: 'cation_exchange_capacity', header: 'Cation Exchange Capacity', info: '' },
										{ field: 'electrical_conductivity', header: 'Electrical Conductivity', info: '' },
										{ field: 'water_holding_capacity', header: 'Water Holding Capacity', info: '' },
										{ field: 'total_nitrogen', header: 'Total Nitrogen', info: '' },
										{ field: 'ammonium', header: 'Ammonium', info: '' },
										{ field: 'nitrate', header: 'Nitrate', info: '' },
										{ field: 'available_nitrogen', header: 'Available Nitrogen', info: '' },
									],
									content: [
										{
											id: 'c6e5c14d-5f0f-433e-9f0f-a57c0c369a18',
											type: 'number',
											label: '',
											fieldInformation: '',
											data: true,
											valid: false,
											col: 'depth_upper',
											fractionDigits: 3,
											units: 'suffix',
											suffix: 'cm',
											validation: true,
										},
										{
											id: 'aabfb140-ca68-4d48-98f7-cba77296c844',
											type: 'number',
											label: '',
											fieldInformation: '',
											data: true,
											valid: false,
											col: 'depth_lower',
											fractionDigits: 3,
											units: 'suffix',
											suffix: 'cm',
											validation: true,
										},
										{
											id: '82d41564-3cec-42f3-8a29-a3071e4d8f5c',
											type: 'number',
											label: '',
											fieldInformation: '',
											data: true,
											valid: false,
											col: 'sand',
											fractionDigits: 3,
											units: 'suffix',
											suffix: '%',
										},
										{
											id: '2daae546-2c3f-455c-b920-55f111ecbbde',
											type: 'number',
											label: '',
											fieldInformation: '',
											valid: false,
											data: true,
											col: 'silt',
											fractionDigits: 3,
											units: 'suffix',
											suffix: '%',
										},
										{
											id: '0e93872c-a58e-4082-8d64-b519da482f1b',
											type: 'number',
											label: '',
											fieldInformation: '',
											data: true,
											valid: false,
											col: 'clay',
											fractionDigits: 3,
											units: 'suffix',
											suffix: '%',
										},
										{
											id: '2b683111-dffb-49de-b337-483592f755e5',
											type: 'number',
											label: '',
											fieldInformation: '',
											data: true,
											valid: false,
											col: 'bulk_density',
											units: 'self',
											fractionDigits: 3,
										},
										{
											id: '02ce65eb-0682-424b-baeb-d2cf2f0444c0',
											type: 'number',
											label: '',
											fieldInformation: '',
											data: true,
											valid: false,
											col: 'total_carbon',
											units: 'self',
											fractionDigits: 3,
										},
										{
											id: 'c98ac9e3-0659-4c54-9cf6-1b842519fa9e',
											type: 'number',
											label: '',
											fieldInformation: '',
											data: true,
											valid: false,
											col: 'soil_organic_carbon',
											units: 'self',
											fractionDigits: 3,
										},
										{
											id: '17763dbf-cdff-44ae-95a8-780e06fcda45',
											type: 'list',
											label: '',
											fieldInformation: '',
											data: true,
											valid: false,
											col: 'soc_method',
										},
										{
											id: '54a5ab1b-edb3-4b13-a50d-b964dbaee81d',
											type: 'number',
											label: '',
											fieldInformation: '',
											valid: false,
											data: true,
											col: 'soil_organic_matter',
											units: 'self',
											fractionDigits: 3,
										},
										{
											id: 'f7c88b02-48e4-4850-8359-ad3c8a97b75e',
											type: 'list',
											label: '',
											fieldInformation: '',
											data: true,
											valid: false,
											col: 'som_method',
										},
										{
											id: 'c922d32c-32cd-4e48-8840-0e1d3fc70eac',
											type: 'number',
											label: '',
											data: true,
											fieldInformation: '',
											valid: false,
											col: 'soil_ph',
											fractionDigits: 3,
										},
										{
											id: '78ccbf30-c998-4c35-8497-1a63d3b2f5a7',
											type: 'list',
											label: '',
											fieldInformation: '',
											data: true,
											valid: false,
											col: 'ph_method',
										},
										{
											id: '764c17b4-b63e-4adf-a20e-f1de918b7d02',
											type: 'number',
											label: '',
											fieldInformation: '',
											data: true,
											valid: false,
											col: 'cation_exchange_capacity',
											units: 'self',
											fractionDigits: 3,
										},
										{
											id: 'd302a890-4de7-45ea-a661-7986e930689c',
											type: 'number',
											label: '',
											fieldInformation: '',
											valid: false,
											data: true,
											col: 'electrical_conductivity',
											units: 'self',
											fractionDigits: 3,
										},
										{
											id: '70281a61-d533-4fd1-ad28-bf30f49b3091',
											type: 'number',
											label: '',
											data: true,
											fieldInformation: '',
											valid: false,
											col: 'water_holding_capacity',
											units: 'self',
											fractionDigits: 3,
										},
										{
											id: '480f663a-3842-4af9-9367-9f9ef68e40de',
											type: 'number',
											label: '',
											data: true,
											fieldInformation: '',
											valid: false,
											col: 'total_nitrogen',
											units: 'self',
											fractionDigits: 3,
										},
										{
											id: 'fbfec679-9a7c-4d72-92e0-dcb635433d40',
											type: 'number',
											data: true,
											label: '',
											fieldInformation: '',
											valid: false,
											col: 'ammonium',
											units: 'self',
											fractionDigits: 3,
										},
										{
											id: 'b6813d45-96d3-410f-921d-e5a951ee7baa',
											type: 'number',
											label: '',
											fieldInformation: '',
											data: true,
											valid: false,
											col: 'nitrate',
											units: 'self',
											fractionDigits: 3,
										},
										{
											id: 'a59c0fbf-5e55-40e8-af45-b4a565c18fde',
											type: 'number',
											label: '',
											data: true,
											fieldInformation: '',
											valid: false,
											col: 'available_nitrogen',
											units: 'self',
											fractionDigits: 3,
										},
									],
								},
							],
						},
						{
							header: 'Biophysical Characteristics',
							id: '3ba2e35c-9452-4207-b0d4-90f1ac0dffde',
							content: [
								{
									id: '215a4089-7bbb-487a-aabe-eb36128131d7',
									type: 'header',
									text: 'Physical',
									data: false,
									category: 'h5',
									size: '16px',
									weight: 'bold',
								},
								{
									id: '775c59b6-331e-4532-ac40-d27280f95174',
									type: 'number',
									// minNumber: 1,
									label: 'Elevation',
									data: true,
									fieldInformation: ' ',
									valid: false,
									showButtons: true,
									suffix: ' masl',
									units: 'suffix',
									fractionDigits: 3,
								},
								{
									id: 'b4c42afe-6ba6-4f0e-9cb8-d97d39cfd249',
									type: 'number',
									// minNumber: 1,
									label: 'Slope',
									data: true,
									fieldInformation: ' ',
									valid: false,
									showButtons: true,
									suffix: ' %',
									units: 'suffix',
									fractionDigits: 3,
								},
								{
									id: 'd0d00b8b-eb67-4f90-b548-d1044a1c7d25',
									type: 'number',
									// minNumber: 1,
									label: 'Slope',
									data: true,
									fieldInformation: ' ',
									valid: false,
									showButtons: true,
									suffix: ' °',
									units: 'suffix',
									fractionDigits: 3,
								},
								{
									id: 'bdacfbe9-1412-476c-9b03-359e70957794',
									type: 'list',
									label: 'Soil Texture',
									fieldInformation: ' ',
									data: true,
									valid: false,
								},
								{
									id: '2d0a7698-55b3-4039-b0e0-259559d02ff3',
									type: 'header',
									text: 'Climate',
									data: false,
									category: 'h5',
									size: '16px',
									weight: 'bold',
								},
								{
									id: 'aad98957-be74-42ed-90c1-213bb7829063',
									type: 'header',
									text: 'This section is used to collect and record climate data from literature sources that describe long-term average weather conditions for a particular location.',
									data: false,
									category: 'p',
									size: '14px',
									weight: '500',
									tolgeeKey: 'climate-desc',
								},
								{
									id: 'b348bca4-c5f5-4018-bb94-df13bfbe578d',
									type: 'number',
									// minNumber: 1,
									data: true,
									label: 'Mean Seasonal Precipitation - S1',
									fieldInformation: ' ',
									valid: false,
									showButtons: true,
									suffix: ' mm',
									units: 'suffix',
									fractionDigits: 3,
								},
								{
									id: '9e950c5a-abab-46fa-9e3c-5aab44572eb3',
									type: 'number',
									data: true,
									// minNumber: 1,
									label: 'Mean Seasonal Precipitation - S2',
									fieldInformation: ' ',
									valid: false,
									showButtons: true,
									suffix: ' mm',
									units: 'suffix',
									fractionDigits: 3,
								},
								{
									id: '3da83bf6-0a23-4b30-ac66-086a13c3fa71',
									type: 'number',
									// minNumber: 1,
									data: true,
									label: 'Mean Annual Rainfall',
									fieldInformation: ' ',
									valid: false,
									showButtons: true,
									suffix: ' mm',
									units: 'suffix',
									fractionDigits: 3,
								},
								{
									id: 'b9e2a951-4d7b-4f47-9029-24540337294e',
									type: 'number',
									// minNumber: 1,
									label: 'Mean Annual Temperature',
									fieldInformation: ' ',
									data: true,
									valid: false,
									showButtons: true,
									suffix: ' °C',
									units: 'suffix',
									fractionDigits: 3,
								},
								{
									id: 'a96ac82b-e806-42db-a83b-b51c95e92e6c',
									type: 'header',
									text: 'Seasonality',
									category: 'h5',
									data: false,
									size: '16px',
									weight: 'bold',
								},
								{
									id: '24a3e408-fa98-4dd0-b418-dc3c02a25267',
									type: 'list',
									label: 'Number of Rainy Seasons',
									fieldInformation: ' ',
									data: true,
									valid: false,
								},
								{
									id: '69c72572-9d4d-4d21-9c25-06c18ac73d8e',
									type: 'list',
									mandatory: false,
									label: 'Season 1 Start',
									maxWords: 1,
									fieldInformation: ' ',
									valid: true,
									data: true,
								},
								{
									id: '665bbb4a-0111-42b5-b2f0-cc82aad68b63',
									type: 'list',
									mandatory: false,
									label: 'Season 1 End',
									maxWords: 1,
									fieldInformation: ' ',
									valid: true,
									data: true,
								},
								{
									id: '72c9d595-83bf-4df0-b4f9-f84700ccdcbe',
									type: 'number',
									// minNumber: 1,
									label: 'Rainy Season 1 Length',
									fieldInformation: ' ',
									valid: false,
									showButtons: true,
									suffix: ' days',
									data: true,
									units: 'suffix',
								},
								{
									id: 'b964b278-c543-4fbe-ba75-2b7706116fdd',
									type: 'list',
									mandatory: false,
									label: 'Season 2 Start',
									maxWords: 1,
									fieldInformation: ' ',
									valid: true,
									data: true,
								},
								{
									id: '8eb721ae-9213-4d25-97da-301f73787af1',
									type: 'list',
									mandatory: false,
									label: 'Season 2 End',
									maxWords: 1,
									fieldInformation: ' ',
									valid: true,
									data: true,
								},
								{
									id: '9464e9fc-381d-4a63-9661-4f5a99b12c6f',
									type: 'number',
									// minNumber: 1,
									label: 'Rainy Season 2 Length',
									fieldInformation: ' ',
									valid: false,
									showButtons: true,
									suffix: ' days',
									data: true,
									units: 'suffix',
								},
								{
									id: '65eff2cb-fcaf-4eed-93e4-532252cb30d8',
									type: 'text area',
									label: 'Site Notes',
									fieldInformation: ' ',
									valid: false,
									data: true,
								},
							],
						},
						// {
						// 	header: 'Seasons & Climate',
						// 	id: '31e57214-3b2c-4797-af82-d3d50ecd0c50',
						// 	content: [
						// 		{
						// 			id: '1560882c-4596-4b6f-b5f6-2aa04520c999',
						// 			type: 'header',
						// 			data: false,
						// 			text: '',
						// 			category: 'p',
						// 			size: '1em',
						// 			weight: '500',
						// 		},
						// 		{
						// 			id: '6d3e2ad7-97a6-4b3f-916a-62f5e00cc709',
						// 			type: 'input-table',
						// 			label: '',
						// 			fieldInformation: '',
						// 			valid: true,
						// 			data: 'table',
						// 			headerButtonLabel: 'Add Time Period',
						// 			placeholder: 'Press Add Time Period',
						// 			deleteMessage: 'Do you want to delete this season?',
						// 			floatingLabel: 'Toggle visible Columns',
						// 			columns: [
						// 				{ field: 'period_name', header: 'Time Period Name', info: '' },
						// 				{ field: 'year', header: 'Year (as stated by author)', info: '' },
						// 				{ field: 'season', header: 'Season (as stated by author)', info: '' },
						// 				{ field: 'rainfall', header: 'Rainfall (mm)', info: '' },
						// 				{ field: 'max_temp', header: 'Mean Max Temp (°C)', info: '' },
						// 				{ field: 'mean_temp', header: 'Mean Temp (°C)', info: '' },
						// 				{ field: 'min_temp', header: 'Mean Min Temp (°C)', info: '' },
						// 				{ field: 'notes', header: 'Notes', info: '' },
						// 			],
						// 			content: [
						// 				{
						// 					id: '5fec44e2-cb3c-42ee-91cf-e29c2b1dae54',
						// 					type: 'text',
						// 					label: '',
						// 					fieldInformation: '',
						// 					data: true,
						// 					valid: false,
						// 					col: 'period_name',
						// 				},
						// 				{
						// 					id: '966614d2-16ca-4743-b345-46e076474cc4',
						// 					type: 'text',
						// 					label: '',
						// 					fieldInformation: '',
						// 					data: true,
						// 					valid: false,
						// 					col: 'year',
						// 				},
						// 				{
						// 					id: 'd6fdb665-c507-4ace-9978-f473f2367bc1',
						// 					type: 'text',
						// 					label: '',
						// 					fieldInformation: '',
						// 					data: true,
						// 					valid: false,
						// 					col: 'season',
						// 				},
						// 				{
						// 					id: 'd0d00b8b-eb67-4f90-b548-d1044a1c7d25',
						// 					type: 'number',
						// 					// minNumber: 1,
						// 					label: '',
						// 					data: true,
						// 					fieldInformation: '',
						// 					valid: false,
						// 					fractionDigits: 3,
						// 					col: 'rainfall',
						// 				},
						// 				{
						// 					id: 'dd374dc9-3034-496d-b008-0a3add8d1f5c',
						// 					type: 'number',
						// 					// minNumber: 1,
						// 					label: '',
						// 					data: true,
						// 					fieldInformation: '',
						// 					valid: false,
						// 					fractionDigits: 3,
						// 					col: 'max_temp',
						// 				},
						// 				{
						// 					id: 'a31ae18b-eb74-4352-94ec-586dd2d0653f',
						// 					type: 'number',
						// 					// minNumber: 1,
						// 					label: '',
						// 					data: true,
						// 					fieldInformation: '',
						// 					valid: false,
						// 					fractionDigits: 3,
						// 					col: 'mean_temp',
						// 				},
						// 				{
						// 					id: 'e48750a4-e976-403c-b313-dfdc5814cf76',
						// 					type: 'number',
						// 					// minNumber: 1,
						// 					label: '',
						// 					data: true,
						// 					fieldInformation: '',
						// 					valid: false,
						// 					fractionDigits: 3,
						// 					col: 'min_temp',
						// 				},
						// 				{
						// 					id: '01ea13d2-b28b-4ccb-83d5-f22f29b4da66',
						// 					type: 'text',
						// 					label: '',
						// 					fieldInformation: '',
						// 					data: true,
						// 					valid: false,
						// 					col: 'notes',
						// 				},
						// 			],
						// 		},
						// 	],
						// },
					],
				},
			],
		},
	],
};
export default configuration;
