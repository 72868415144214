const configurationCrop = 	{
	header: 'Experimental Design',
	content: [
		{
			id: '334c874a-5ac9-4625-81a4-bf3e01c5e6ca',
			type: 'text',
			label: 'Experimental Design',
			fieldInformation: ' ',
			valid: false,
			data: true,
		},
		{
			id: '3e12da9e-c1a8-4388-ab63-a3deed04ac4c',
			type: 'list',
			label: 'Is the experiment part of a long-term trial?',
			fieldInformation: ' ',
			valid: false,
			data: true,
		},
		{
			id: '4dee0490-b049-4208-9732-8697174caee1',
			data: true,
			type: 'calendar',
			mandatory: false,
			label: 'Experiment Start Date (Start)',
			fieldInformation: ' ',
			year: false,
			valid: true,
			maxDate: true,
		},
		{
			id: '790b83a3-b98a-4612-8670-9a4a2be6acfc',
			data: true,
			type: 'calendar',
			mandatory: false,
			label: 'Experiment Start Date (End)',
			fieldInformation: ' ',
			year: false,
			valid: true,
			maxDate: true,
		},
		{
			id: '72fcb0cf-535f-442d-a515-8be49f02c30e',
			type: 'number',
			label: 'Size of Experimental Plot',
			fieldInformation: ' ',
			valid: false,
			data: true,
			unit: 'suffix',
			suffix: 'm2',
			fractionDigits: 3,
		},
		{
			id: '0a8c89bd-807b-49e9-a065-8e570a015a18',
			type: 'number',
			label: 'Size of Harvest Plot',
			fieldInformation: ' ',
			valid: false,
			data: true,
			unit: 'suffix',
			suffix: 'm2',
			fractionDigits: 3,
		},
		{
			id: '8d1fb994-a4cc-4875-8d06-74df64fbd4aa',
			type: 'text area',
			label: 'Site Notes',
			fieldInformation: ' ',
			valid: false,
			data: true,
		},
	],
};

export default configurationCrop;
