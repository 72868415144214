/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslate } from '@tolgee/react';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Actions } from '../../reducer/actions';
import { http } from '../../services/httpService';
import AuthService from '../../services/httpService/authServices';
import { translate } from '../../utils';

const Home = (props) => {
	const { t } = useTranslate();

	const { setLoading } = props;

	const navigate = useNavigate();

	const dispatch = useDispatch();
	const setAccessToken = (payload) => dispatch({ type: Actions.SetAccessToken, payload });

	const setRole = (payload) => dispatch({ type: Actions.SetRole, payload });

	const setUserId = (payload) => dispatch({ type: Actions.SetUserId, payload });

	const setCurrentPage = (payload) => dispatch({ type: Actions.SetCurrentPage, payload });

	useEffect(
		() => {
			setLoading(true);
			const temp = window.location.hash.split('=');
			if (temp.length < 2) {
				const savedUID = localStorage.getItem('userId');
				if (savedUID) {
					http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
					http.defaults.headers.common.User = `${savedUID}`;
					AuthService.validate()
						.catch((error) => {
							if (error.response.status === 401) {
								setAccessToken(null);
								setRole(null);
								setUserId(null);
								localStorage.removeItem('accessToken');
								localStorage.removeItem('role');
								localStorage.removeItem('userId');
							}
						})
						.finally(() => {
							setLoading(false);
						});
				} else {
					setLoading(false);
				}
			} else {
				setLoading(false);
				const token = temp[1]?.split('&')[0];
				if (token) {
					http.defaults.headers.common.Authorization = `Bearer ${token}`;
					localStorage.setItem('accessToken', token);
					setAccessToken(token);
					setCurrentPage('home');
					navigate('/Home');
				}

				const role = temp[3]?.split('&')[0];
				if (role) {
					localStorage.setItem('role', role);
					setRole(role);
					setCurrentPage('home');
					navigate('/Home');
				}

				const userId = temp[2]?.split('&')[0];
				if (userId) {
					http.defaults.headers.common.User = `${userId}`;
					localStorage.setItem('userId', userId);
					const temp2 = userId.replace('%7C', '|');
					setUserId(temp2);
					setCurrentPage('home');
					navigate('/Home');
				}
			}
		}, []
	);

	return (
		<div className="home p-grid">
			<div className="image cell p-col-12" />
			<div className="text cell p-col-8 p-offset-2">
				<h3>{translate(t, 'Evidence for Resilient Agriculture (ERA)', 'home-title')}</h3>
				<p>{translate(t, 'Welcome to the Evidence for Resilient Agriculture (ERA) platform. Our intuitive interface guides you through extracting critical data from publications on agronomy and livestock in a systematic and organized manner.', 'home-body-1')}</p>
				<p>{translate(t, 'To begin extracting data, please create an account and log in. You will then access a dashboard where you can extract data from new studies and manage your existing extractions efficiently.', 'home-body-2')}</p>
			</div>
			<div className="cell p-col-12" />
		</div>
	);
};

export default Home;
