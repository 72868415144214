/* eslint-disable max-len */
const configuration = {
	header: 'Tillage',
	id: '4016d4ef-abb1-4052-a2fc-8f421248afd4',
	description: true,
	descriptionList: [{
		type: 'p',
		name: 'tillage-subdesc-1',
	}],
	content: [
		{
			id: 'd59ff545-246d-4add-91dc-ae64633ce9e9',
			data: true,
			type: 'text',
			label: 'Practice Name',
			fieldInformation: ' ',
		},
		{
			id: '0b6522bf-3f56-47f5-825c-a6747ed14727',
			type: 'text area',
			label: 'General Notes',
			fieldInformation: ' ',
			valid: false,
			columnNumber: 1,
			data: true,
		},
		{
			id: '796a2830-ccd8-424c-95fd-bfca552a1611',
			type: 'input-table',
			label: '',
			fieldInformation: ' ',
			valid: true,
			headerButtonLabel: 'Add Row',
			placeholder: 'Press Add Row',
			deleteMessage: 'Do you want to delete this row?',
			data: 'table',
			columns: [
				{ field: 'practice', header: 'Practice', info: '' },
				{ field: 'till_meth_impl', header: 'Tillage Method of Implement', info: '' },
				{ field: 'soil_prep_methods', header: 'Other Soil Prep Methods', info: '' },
				{ field: 'depth', header: 'Tillage Depth', info: '' },
				{ field: 'freq', header: 'Tillage Frequency Per Season', info: '' },
				{ field: 'percentage', header: '% Field Tilled', info: '' },
				{ field: 'tilled_width', header: 'Tilled Strip Width', info: '' },
				{ field: 'untilled_width', header: 'Untilled Strip Width', info: '' },
			],
			timeFields: 'simple',
			content: [
				{
					id: '64916031-13b7-426b-99a1-36a8a20b9c92',
					data: true,
					type: 'list',
					// preloadTarget: 'short_value',
					col: 'practice',
					editable: false,
					definition: {
						id: 'a89bb12e-24b6-4476-ab67-aac1094f1d74',
						type: 'text area',
						label: 'Definition',
						fieldInformation: ' ',
						valid: false,
						disabled: true,
						placeholder: 'Practice has no value.',
					},
				},
				// {
				// 	id: 'afba0c86-2ffd-4778-b9fc-6df9dcc44eb3',
				// 	data: true,
				// 	type: 'calendar',
				// 	mandatory: false,
				// 	year: false,
				// 	valid: true,
				// 	maxDate: true,
				// 	selectionMode: 'single',
				// 	col: 'date_occurrence',
				// },
				// {
				// 	id: '4a90a49f-1808-463d-bfe2-0ee7775ef4a0',
				// 	type: 'number',
				// 	showButtons: true,
				// 	valid: false,
				// 	data: true,
				// 	col: 'days_after_plant',
				// },
				// {
				// 	id: '3e94895d-4883-4df9-93f4-ad7aa01ca540\n',
				// 	type: 'number',
				// 	valid: false,
				// 	data: true,
				// 	col: 'days_after_germ',
				// },
				{
					id: 'e99475b7-9baa-487d-9265-60b35fad0671',
					type: 'table',
					label: '',
					data: true,
				},
				{
					id: '6829b6f3-aac6-4abb-92f8-77862b4ba395',
					type: 'list',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'till_meth_impl',
					data: true,
				},
				{
					id: '9c596714-94c1-43a3-a512-9d376f456267',
					type: 'list',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'soil_prep_methods',
					data: true,
				},
				{
					id: 'f33c1c2c-2d6e-4e58-82d3-68ff6057be16',
					type: 'number',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'depth',
					data: true,
					unit: 'suffix',
					suffix: 'cm',
					fractionDigits: 3,
				},
				{
					id: 'c98d95af-5efd-44a3-be55-65d677ca2ae2',
					type: 'number',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'freq',
					data: true,
					fractionDigits: 3,
				},
				{
					id: '444bd5ec-97d2-414d-9d70-c744b00f6ae4',
					type: 'number',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'percentage',
					data: true,
					unit: 'suffix',
					suffix: '%',
					fractionDigits: 3,
				},
				{
					id: 'dfedbc51-d17e-40ef-9e17-9286389d178d',
					type: 'number',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'tilled_width',
					data: true,
					unit: 'suffix',
					suffix: 'cm',
					fractionDigits: 3,
				},
				{
					id: 'bd3b83d4-4755-4a03-bf86-2a24523e0725',
					type: 'number',
					label: '',
					fieldInformation: '',
					valid: false,
					col: 'untilled_width',
					data: true,
					unit: 'suffix',
					suffix: 'cm',
					fractionDigits: 3,
				},
			],
		},
	],
};

export default configuration;
